<template>
  <div class="loading" v-show="carregando">
    <div class="center-spinner" v-show="carregando">
      <ProgressSpinner />
    </div>
  </div>
  <div class="grid p-fluid" style="margin: -1rem -1rem 0 -1rem">
    <Image
      :src="produtoOb.foto"
      alt="Image Text"
      imageClass="max-w-full h-auto block"
      :preview="true"
      class="block"
    />

    <div class="produto-resumido-titulo text-center text-lg mt-2 px-1 w-full">
      <span class="w-full">{{ produtoOb.nome }}</span>
    </div>
    <div class="text-center px-1 w-full">
      <div class="w-full titulo-1">{{ produtoOb.descricao }}</div>
    </div>
    <div
      v-if="Object.values(produtoOb.personalizacoes).length > 0"
      class="col-12"
    >
      <div v-for="a of Object.values(produtoOb.personalizacoes)" :key="a.id">
        <div class="primary-color col-12">
          <div class="w-full titulo-2">{{ a.nome }}: escolha {{ (a.limiteMinimo != a.limiteMaximo)?('de ' + a.limiteMinimo + ' a ' + a.limiteMaximo + ' ítens'):((a.limiteMinimo == 1)?a.limiteMinimo + ' item':a.limiteMinimo + ' itens') }}</div>
          <div class="w-full titulo-3" v-if="a.descricao != ''">{{ a.descricao }}</div>
        </div>

        <div
          class="col-12 grid p-fluid"
          v-for="i of Object.values(a.itens)"
          :key="i.id"
        >
          <div class="col-6 md:col-8">
            <div class="w-full titulo-1">{{ i.nome }}</div>
            <div class="w-full titulo-1">
              {{ $utils.formatCurrency(i.valor) }}
            </div>
          </div>
          <div class="col-6 md:col-4">
            <InputNumber
              :ref="'inputPerson_' + a.id"
              v-model="i.qntSelecionado"
              showButtons
              buttonLayout="horizontal"
              decrementButtonClass="adicionar-carrinho-quantidade-button"
              incrementButtonClass="adicionar-carrinho-quantidade-button"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              mode="decimal"
              :readonly="true"
              :allowEmpty="false"
              :min="0"
              inputClass="text-center"
              :disabled="validacoes.personalizacoes[a.id] == 'ok'"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="produto-resumido-titulo text-center text-lg mt-2 px-1 w-full">
      <span class="w-full"
        ><i class="pi pi-ticket" style="font-size: 1rem"></i> Alguma
        Observação</span
      >
    </div>
    <div class="col-12">
      <Textarea v-model="produtoOb.observacao" rows="2" />
    </div>
    <div class="col-4">
      <InputNumber
        v-model="produtoOb.qnt"
        showButtons
        buttonLayout="horizontal"
        decrementButtonClass="adicionar-carrinho-quantidade-button"
        incrementButtonClass="adicionar-carrinho-quantidade-button"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        mode="decimal"
        :readonly="true"
        :allowEmpty="false"
        :min="1"
        inputClass="text-center"
      />
    </div>
    <div class="col-8">
      <Button
        :label="$utils.formatCurrency(totalProduto)"
        icon="pi pi-shopping-cart"
        iconPos="right"
        class="adicionar-carrinho-quantidade-button"
        :disabled="!validacoes.geral"
        @click="addItem"
      />
    </div>
  </div>
  
  <Toast position="top-right" />
</template>

<script>
export default {

  name: "AdicionarCarrinho",

  props: [
    "value"
  ],

  emits: [
    "add"
  ],

  data() {

    return {

      // Controle de apresentação
      carregando: true,
      validacoes: {
        personalizacoes: {},
        geral: false
      },
      
      // Dados
      produtoOb: { personalizacoes: [], qnt: 1 },
      totalProduto: 0,
      
    };

  },

  mounted() {

    this.carregaInformacoes();

  },

  watch: {

    "produtoOb.personalizacoes": {
      handler: function (personalizacoes) {

        this.totalProduto = 0;
        
        let valicacoesGeral = true;

        for (let p of Object.values(personalizacoes)) {
          let qi = 0;
          for (let i of Object.values(p.itens)) {
            this.totalProduto += i.valor * i.qntSelecionado;
            qi += i.qntSelecionado;
          }

          if(qi < p.limiteMinimo) {
            this.validacoes.personalizacoes[p.id] = "pendente";
            valicacoesGeral = false;
          } else if(qi >= p.limiteMaximo) {

            if(typeof this.$refs['inputPerson_' + p.id] != 'undefined') {
              for(const cc of this.$refs['inputPerson_' + p.id]) {
                cc.clearTimer();
              }
            }

            this.validacoes.personalizacoes[p.id] = "ok";
          }

        }

        this.validacoes.geral = valicacoesGeral;

        this.totalProduto += this.produtoOb.valor;

        this.totalProduto *= this.produtoOb.qnt;

      },
      deep: true,
      immediate: true,
    },

    produtoOb: async function (produtoOb) {
      if(produtoOb != null) {

        this.produtoOb.qnt = 1;
        this.totalProduto = produtoOb.valor;

        let qOpcs = 1;

        for (let p of Object.values(produtoOb.personalizacoes)) {
          this.validacoes.personalizacoes[p.id] = "pendente";
          for (let i of Object.values(p.itens)) {
            i.qntSelecionado = 0;
            qOpcs++
          }
        }

        // #region POG //
        const interval = setInterval(() => {
          const inputs = document.querySelectorAll("input.p-inputnumber-input");
          if(inputs.length == qOpcs) {
            clearInterval(interval);
            inputs.forEach(i => {
              i.setAttribute("disabled", "disabled");
            });
          }
        }, 500);
        // #endregion POG //

      } 
    },

  },

  methods: {

    async carregaInformacoes() {
      
      this.carregando = true;

      const response = await this.$api.get(
        "/produtos/" + this.$root.empresa.id + "/" +
          this.value.idCategoria +
          "/" +
          this.value.id
      );

      let dados = response.data;

      if (dados.success) {
        
        if (typeof dados.data.personalizacoes == "undefined") {
          dados.data.personalizacoes = [];
        }

        this.validacoes = {
          personalizacoes: {},
          geral: Object.keys(dados.data.personalizacoes).length == 0
        };

        for (let p of Object.values(dados.data.personalizacoes)) {
          if(typeof p.itens == "undefined")
            p.itens = [];

          for (let i of Object.values(p.itens)) {
            i.qntSelecionado = 0;
          }
        }

        dados.data.qnt = 1;

        this.produtoOb = dados.data;

      }

      this.carregando = false;

    },

    async addItem() {
      
      delete this.produtoOb.disponibilidade;

      let dados = {
        produto: this.produtoOb,
        idLoja: this.$root.empresa.id,
        valor: this.totalProduto
      };
     
      const response = await this.$api.post("/carrinho", dados);

      this.$toast.add({
        severity: response.data.success ? "success" : "error",
        summary: response.data.success ? "Sucesso!" : "ERRO!",
        detail: response.data.message,
        life: response.data.success ? 3000 : 5000,
      });

      if (response.data.success) {
        this.$root.carregaCarrinho();
        this.$emit("add");
      }

    },
    
  },
};
</script>

<style lang="scss" scoped>

.titulo-3 {
  color: white;
  font-size: 0.8rem;
}

.adicionar-carrinho-quantidade-button {
  background-color: var(
    --primaryBackgroundColor,
    $primaryBackgroundColor
  ) !important;
  border-color: var(
    --primaryBackgroundColor,
    $primaryBackgroundColor
  ) !important;
}

:deep(input.p-component.p-inputnumber-input:disabled) {
  opacity: 1 !important;
}

</style>