<template>
  <div v-if="withLayout" class="layout-wrapper">
    <AppTopBar />

    <div class="layout-main md:px-3 pb-5">
      <div class="ajuste-altura">
        <router-view />
      </div>
    </div>

    <AppFooter />
  </div>
  <div v-else><router-view /></div>
</template>

<script>
import AppTopBar from "./componentes/AppTopbar.vue";
import AppFooter from "./componentes/AppFooter.vue";

import Cookie from 'js-cookie';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";
import { createGtm } from '@gtm-support/vue-gtm';
import moment from "moment-timezone";

import "./assets/layout/App.scss";

export default {
  data() {
    return {
      withLayout: true,
      corApp: '',

      //USADO GLOBALMENTE
      version: "1.0.2",
      logoDefault: require("@/assets/imagens/logo-npc.png"),
      logo: null,
      user: null,
      empresa: null,
      carrinho: null,
      carrinhoQtd: 0,
      pedidos:[],
      pedidosQnt:0,
      gps: null,

      conteudo: [],

      timerFuncionamento: null,
      timerPedidos: null,

    };
  },
  created() {

    const self = this;
    const auth = getAuth();
    const database = getDatabase();

    this.logo = this.logoDefault;

    onAuthStateChanged(auth, (user) => { 

      self.user = null;

      if (user) {

        self.carregaUsuario();

      }

    });

    let parametros = window.location.pathname.substr(1).split("/").filter(function(el) { return el; });

    let limpaURL = parametros.length > 0;

    // if(window.location.hostname == "localhost") {
    //   if(parametros[0] != "rtyOI537kwWQUeZxrZVErSYZM5e2")
    //     parametros.unshift('rtyOI537kwWQUeZxrZVErSYZM5e2');
    // }

    const paramsUser = Cookie.get('params-id');

    if(parametros.length == 0 && typeof paramsUser != 'undefined') {
      parametros = paramsUser.split(',');
    }

    self.$api.post("/empresas/sessao", {
      'dominio' : window.location.hostname,
      'parametros' : parametros,
    }).then((res) => {

      if(res.data.success) { 
        
        Cookie.set('params-id', parametros, { secure: window.location.hostname != "localhost", expires: 365 });

        self.empresa = res.data.data;

        // #region GTM //

        if(typeof self.empresa.gtm == "string" && self.empresa.gtm != "") {

          self.$.appContext.app.use(
            createGtm({
              id: self.empresa.gtm, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
              enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
              debug: false, // Whether or not display console logs debugs (optional)
              loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
              vueRouter: self.$router, // Pass the router instance to automatically sync with router (optional)
              // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            }),
          );

        }

        // #endregion //

        // #region PARAMETROS PERSONALIZADOS //

        if(parametros.length >= 2) {

          let acao = "";
          let acao_id = "";
          if(parametros[0] == self.empresa.id) {
            acao = parametros[1];
            acao_id = parametros[2]
          } else {
            acao = parametros[0];
            acao_id = parametros[1];
          }

          if(typeof acao_id != "string")
            acao_id = "";

          if(acao != "") {

            self.$router.push('/' + acao + (acao_id != "" ? "/" + acao_id : ""));
            history.pushState("", document.title, "/#/" + acao + (acao_id != "" ? "/" + acao_id : ""));

            limpaURL = false;

          }

        }

        if(limpaURL) {
          history.pushState("", document.title, "/#/");
        }

        // #endregion //

        // #region Horário de Funcionamento //

        if(self.empresa.funcionamentoInicio != "00:00" || self.empresa.funcionamentoFim != "23:59") {
          self.timerFuncionamento = setInterval(self.timerFnFuncionamento, 1000);
        }

        // #endregion //

        self.carregaCarrinho();
        self.carregaPedidos();

      } else {

        window.location.href = '/#/erro';

      }

    });

    const versaoRef = ref(database, 'GrerenciarVesao/ecommerce');
    onValue(versaoRef, (snapshot) => {          

      if(snapshot.exists()) {

        if (window.location.hostname != "localhost" && snapshot.val() != self.version)
          location.reload(true);

      }

    });

  },
  mounted() {

    if(this.$utils.isMobileOrTablet())
      document.body.classList.add('is-touch');

    if(/iPhone/i.test(navigator.userAgent)) {
      document.body.classList.add('is-iphone');
    }

    const self = this;

    navigator.geolocation.getCurrentPosition( function(pos) {
      self.gps = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
      };
    }, function(err) { 
      console.error(err);
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });

  },
  watch: {

    $route() {
      this.withLayout = !this.$route.matched.some(
        (record) => record.meta.freeLayout
      );
      this.$toast.removeAllGroups();
    },

    user: function (u) {

      this.logo = this.logoDefault;

      if (u != null) {
        if(typeof u.empresas == "object" && typeof u.empresas.foto == "string" && u.empresas.foto != "") {  
          this.logo = u.empresas.foto;
        }
      }

    },

    empresa: function (e) {
      if(e != null) {
        this.corApp = e.config.cabecalho.color;
      } else {
        this.corApp = '';
      }
    },

    corApp: function (cor) {
      if(cor) {
        document.body.style = '--primaryBackgroundColor: #' + cor + '; --secondaryColor: #' + cor;
      } else {
        document.body.style = '';
      }
    }

  },
  methods: {

    timerFnFuncionamento() {

      const agora = moment().tz("America/Sao_Paulo");
      const inicio = moment(agora.format("YYYY-MM-DD") + " " + this.empresa.funcionamentoInicio, "YYYY-MM-DD HH:mm", true).tz("America/Sao_Paulo");
      const fim = moment(agora.format("YYYY-MM-DD") + " " + this.empresa.funcionamentoFim, "YYYY-MM-DD HH:mm", true).tz("America/Sao_Paulo");

      if(agora < inicio || agora > fim) {
        alert("ATENÇÃO: Empresa fechada! O horário de funcionamento é de " + this.empresa.funcionamentoInicio + " às " + this.empresa.funcionamentoFim + ".");
        clearInterval(this.timerFuncionamento);
      } else if(agora > fim.subtract(10, "minutes")) {
        alert("ATENÇÃO: O horário de funcionamento da empresa é de " + this.empresa.funcionamentoInicio + " às " + this.empresa.funcionamentoFim + ".");
        clearInterval(this.timerFuncionamento);
      }

    },

    isDesktop() {
      return window.innerWidth > 1024;
    },

    carregaUsuario() {

      const self = this;

      this.$api.get("/usuarios/info").then((res) => {

        if(res.data.success) {

          self.user = res.data.data;

          self.carregaCarrinho();
          self.carregaPedidos();

        } else {

          getAuth().signOut();

        }

      });

    },

    async carregaCarrinho() {

      if(this.empresa == null) {
        this.carrinho = null;
        this.carrinhoQtd = 0;
        return;
      }

      const response = await this.$api.get("/carrinho/" + this.empresa.id);
      if(response.data.success) {
        this.carrinho = response.data.data;
        this.carrinhoQtd = Object.keys(this.carrinho.listaProdutos).length;
      } else {
        this.carrinho = null;
        this.carrinhoQtd = 0;
      }

    },

    async carregaPedidos(){

      if(this.empresa == null) {
        this.pedidos = null;
        this.pedidosQnt = 0;
        return;
      }

      const response = await this.$api.get("/usuarios/pedidos/" + this.empresa.id);
      if(response.data.success) {
        this.pedidos = response.data.data;
        this.pedidosQnt = this.pedidos.length;

        if(this.pedidos.length > 0)
          setTimeout(this.carregaPedidos, 5000);

      } else {
        this.pedidos = null;
        this.pedidosQnt = 0;
      }

    },

    async carregaConteudo() {

      let res = await this.$api.get("/empresas/home/" + this.empresa.id);

      res = res.data;

      if (res.success) {
        this.conteudo = res.data;
      } else {
        this.conteudo = [];
      }

    }

  },
  components: {
    'AppTopBar': AppTopBar,
    'AppFooter': AppFooter,
  },
};
</script>
